<template>
  <section>
    <div class="mb-5" v-for="(link, index) in links" :key="index">
      <router-link v-if="showCategory(link.link)" class="category" :to="link.link">
        {{ link.name }}
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "Full day Tours",
          link: "/tours/full-day-tours",
        },
        {
          name: "Tuk Tuk Tours",
          link: "/tours/tuk-tuk-tours",
        },
        {
          name: "Cultural Tours",
          link: "/tours/cultural-tours",
        },
        {
          name: "Historical Tours",
          link: "/tours/historical-tours",
        },
        {
          name: "Architecture",
          link: "/tours/portugal-architecture",
        },
      ],
    };
  },
  methods: {
    showCategory(categoryLink){
        return categoryLink !== this.$route.path
    }
  }
};
</script>

<style lang="scss" scoped>
.category {
  padding: 8px 18px;
  border: 2px solid;
  font-weight: 600;
  border-radius: 4px;
  &:hover {
    background-color: #0e3d4d;
    color: white;
    cursor: pointer;
  }
}
</style>
