<template>
    <section>
       <div class="info--container is-flex is-align-items-flex-start">
        <vue-lottie class="lottie-an mr-4" :options="lottieOptions" width="100%" height="100%" style="margin: 0;"/>
        <p class="text">Can't find the tour you want? Send a<a class='link' href='https://wa.me/351920043375' target='_blank'> message</a> or <a class='link' 
            href='mailto:support@guiders.pt'>email</a> telling us where you'd like to visit in Portugal and your group size. We'll plan the tour and send you a price quote!</p>
      </div>
    </section>
  </template>
  
  <script>
  import VueLottie from 'vue-lottie';
  export default {
    components: {
      VueLottie,
    },
    data() {
      return {
        lottieOptions: {
                  animationData: require('@/assets/animations/info-an.json'),
                  loop: true,
                  autoplay: true,
              },
            }
          }
  }
  </script>
  <style scoped>
  .lottie-an{
    margin: 0;
  }
  .info--container{
    background-color: #f6fbff;
     padding: 22px;
     border-radius: 17px;
   }
  </style>
  